/*
 * @Description: 新闻管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:55:04
 */
import request from '@/utils/request'

/**
 * 查询商品列表
 */
export const getNewsList = async (data) => request({ url: "/news/getByList", method: "POST", data })

/**
 * 根据id查询商品详情
 */
export const getNewsById = async (data) => request({ url: "/news/getById", method: "POST", data })

/**
 * 根据id列表查询商品列表
 */
 export const getNewsByIds = async (data) => request({ url: "/news/getByIds", method: "POST", data })

/**
 * 新增商品
 */
export const addNews = async (data) => request({ url: "/news/add", method: "POST", data })

/**
* 编辑商品
*/
export const editNews = async (data) => request({ url: "/news/edit", method: "POST", data })

/**
* 复制商品
*/
export const copyNews = async (data) => request({ url: "/news/copy", method: "POST", data })