<!--
 * @Description: 富文本区域  富文本组件!!!!!!!!
-->
<template>
	<config-item :label='label'>		
		<FaRichText v-model="mValue" :placeholder='mOptions.placeholder'  v-bind="mOptions" />
	</config-item>
</template>

<script>
	import schemaMixin from "@/mixin/schemaMixin";
	export default {
		name: "SchemaRichTxt",
		mixins: [schemaMixin],
	};
</script>